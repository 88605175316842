<template>
    <v-card flat>
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <new-customer-dialog v-model="customerDialog" @create="refetchCustomers" :name="name"></new-customer-dialog>
        <v-container>
            <nice-header>Create manual order<span v-if="product"> for {{ product.name }}</span></nice-header>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12" md="6" class="py-0">
                            <v-autocomplete
                                    :items="customers"
                                    label="Customer"
                                    clearable
                                    v-model="form.customer"
                                    :search-input.sync="name"
                                    :rules="[rules.required]"
                                    item-text="name"
                                    item-value="id"
                                    rounded
                                    outlined
                            >

                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account-circle</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.name"></v-list-item-title>
                                        <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>

                                <template v-slot:no-data>
                                  <v-list-item @click="customerDialog = true">
                                        <v-list-item-title>
                                            Cannot find a customer called <strong>{{name}}</strong> - click here to create one
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>

                            </v-autocomplete>
                        </v-col>
                      <v-col md="6" class="py-0"></v-col>
                      <v-col cols="12" class="py-0">
                        <v-subheader>
                          Collection/Delivery
                        </v-subheader>
                      </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <v-select
                                rounded
                                outlined
                                label="Hire type"
                                :items="hireTypes"
                                v-model="form.hireType"
                                :rules="[rules.required]"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="6" class="py-0">
                          <address-field
                              rounded
                              outlined
                              :disabled="form.hireType === 'collection'"
                              label="Delivery address"
                              v-model="form.deliveryAddress"
                              :rules="form.hireType === 'delivery' ? [rules.required] : []"
                          ></address-field>
                        </v-col>

                        <v-col md="6" class="py-0"></v-col>
                        <v-col cols="12" class="py-0">
                            <v-subheader>
                                Assets and price
                            </v-subheader>
                        </v-col>

                        <v-col cols="12" md="6" class="py-0">
                            <v-autocomplete
                                    v-if="product"
                                    :items="product.assets"
                                    label="Assets"
                                    multiple
                                    clearable
                                    v-model="form.assets"
                                    :rules="[rules.moreThanNone]"
                                    item-text="sku"
                                    item-value="id"
                                    rounded
                                    outlined
                                    :allow-overflow="false"
                            >
                                <template v-slot:selection="{ attrs, select, selected, item, index }">
                                    <v-chip
                                            v-if="index === 0"
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click:close="remove(item)"
                                    >
                                        {{ item.sku }}
                                        <span v-if="item.size">{{store.labelOne}}: {{ item.size }}</span>
                                        <span v-if="item.gender">{{store.labelTwo}}: {{ item.gender }}</span>
                                        <span v-if="item.brand">{{store.labelThree}}: {{ item.brand }}</span>
                                    </v-chip>
                                    <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                    >
                                      (+{{ form.assets.length - 1 }} others)
                                    </span>

                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ product.name }} ({{item.sku}})
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div>
                                                <span v-if="item.size">{{store.labelOne}}: {{ item.size }}</span>
                                                <span v-if="item.gender">{{store.labelTwo}}: {{ item.gender }}</span>
                                                <span v-if="item.brand">{{store.labelThree}}: {{ item.brand }}</span>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>


                        <v-col cols="12" md="3" class="py-0">
                            <v-text-field
                                    v-if="product"
                                    outlined
                                    rounded
                                    v-model="form.value"
                                    label="Price"
                                    :rules="[rules.float]"
                                    :prefix="product.catalog.currency.symbol"
                            />
                        </v-col>
                        <v-col md="3" class="py-0"></v-col>


                        <v-col cols="12" class="py-0">
                            <v-subheader>
                                Dates and times
                            </v-subheader>
                        </v-col>

                        <v-col cols="6" md="3" class="py-0">
                            <time-picker
                                    label="Start time"
                                    v-model="form.startTime"
                            ></time-picker>
                        </v-col>
                        <v-col cols="6" md="3" class="py-0">
                            <date-picker
                                    label="Start date"
                                    v-model="form.startDate"
                                    :rules="[rules.required]"
                                    :min="today"
                            />
                        </v-col>
                        <v-col md="6" class="py-0"></v-col>

                        <v-col cols="6" md="3" class="py-0">
                            <time-picker
                                    label="End time"
                                    v-model="form.endTime"

                            ></time-picker>
                        </v-col>
                        <v-col cols="6" md="3" class="py-0">
                            <date-picker
                                    label="End date"
                                    v-model="form.endDate"
                                    :rules="[rules.required]"
                                    :min="minEndDate"
                            />
                        </v-col>

                        <v-col cols="12" class="py-0">
                            <v-subheader>Extras</v-subheader>
                            <v-col cols="12" v-for="i in extraCount" :key="i" class="py-0">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                                rounded
                                                outlined
                                                :rules="[rules.required]"
                                                label="Description"
                                                v-model="extras[i-1].description"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                                rounded
                                                outlined
                                                v-if="product"
                                                :prefix="product.catalog.currency.symbol"
                                                :rules="[rules.required, rules.float]"
                                                label="Value"
                                                v-model="extras[i-1].value"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-btn icon class="mt-2" @click="removeExtra(i)">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-btn
                                outlined
                                rounded
                                @click="addExtra"
                                color="primary"
                            >
                                Add more extras
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    color="primary"
                    :disabled="!valid"
                    :loading="loading"
                    @click="createOrder"
                >
                    Create order
                </v-btn>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
    import { product, createManualOrder, customers } from '@/graphql'
    import AddressField from "@/components/AddressField";
    import moment from 'moment'
    import { mapState } from 'vuex'
    import { stores } from '@/graphql'


    export default {
        components: { AddressField },
        data: () => ({
            valid: null,
            loading: false,
            error: false,
            errorMessage: null,
            name: null,
            form: {
                customer: null,
                startTime: '09:00',
                endTime: '16:00',
                startDate: null,
                endDate: null,
                assets: [],
                value: 0,
                hireType: 'collection',
                deliveryAddress: null
            },
            hireTypes: [
                { text: 'Collection from store', value: 'collection' },
                { text: 'Delivery', value: 'delivery' },
            ],
            extras: [
                {description: "", value: 0},
            ],
            rules: {
                required: v => !!v || 'This field is required',
                moreThanNone: v => v.length > 0 || 'Please select at least one item',
                float: v => !isNaN(parseFloat(v)) || 'Must be a number',

            },
            extraCount: 1,
            customerDialog: false,
        }),
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.selectedStore) {
                    return this.stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            },
            today() {
                return moment().format('YYYY-MM-DD')
            },
            minEndDate() {
                if (this.form.startDate) {
                    return moment(this.form.startDate).format('YYYY-MM-DD')
                }
                return this.today
            },
            startDatetime() {
                return `${this.form.startDate}T${this.form.startTime}:00Z`
            },
            endDatetime() {
                return `${this.form.endDate}T${this.form.endTime}:00Z`
            }
        },
        apollo: {
            customers: {
                query: customers,
                variables () {
                    return {
                        store: this.selectedStore
                    }
                }
            },
            stores,
            product: {
                query: product,
                variables() {
                    return {
                        pk: this.$route.params.product
                    }
                }
            }
        },
        methods: {
            remove(item) {
                const index = this.assets.indexOf(item.name);
                if (index >= 0) this.assets.splice(index, 1)
            },
            removeExtra(index) {
                this.extras.splice(index, 1);
                this.extraCount--
            },
            addExtra () {
                this.extras.push({ description: null, value: 0 });
                this.extraCount ++
            },
            refetchCustomers (pk) {
                this.$apollo.queries.customers.refetch().then(() => {
                    this.form.customer = pk;
                });
            },
            createOrder () {
                this.loading = true;
                const { customer, assets, value, hireType, deliveryAddress } = this.form;
                const { startDatetime, endDatetime, extras } = this;
                this.$apollo.mutate({
                    mutation: createManualOrder,
                    variables: {
                        catalog: this.product.catalog.hashId,
                        deliveryAddress: deliveryAddress ? deliveryAddress.text : '',
                        customer, assets, value, startDatetime, endDatetime, extras, hireType,
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then(({ data }) => {
                    const { pk } = data.createManualOrder;
                    this.loading = false;
                    this.$router.push(`/orders/${pk}`)
                })
            }
        },
        created () {
            if (this.$route.query.customer) {
                this.form.customer = this.$route.query.customer
            }
        }
    }
</script>
