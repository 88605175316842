<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-text-field
        rounded
        outlined
        :disabled="disabled"
        v-on="on"
        readonly
        :value="value && value.text ? value.text : value"
        :label="label || 'Store Address'"
        autocomplete="new-password"
      ></v-text-field>
    </template>
    <v-card flat>
      <nice-header>Address lookup</nice-header>
      <v-card-text>
  <vuetify-google-autocomplete
      id="map"
      rounded
      outlined
      append-icon="mdi-magnify"
      v-bind:disabled="disabled"
      placeholder="Start typing"
      v-on:placechanged="selectAddress"
  />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text color="primary" @click="dialog=false">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

export default {
  components: { VuetifyGoogleAutocomplete },
  name: 'AddressField',
  props: ['value', 'label', 'disabled', 'store'],
  data: () => ({
    dialog: false,
    rules: {
      required: v => !!v || 'This field is required',
      minThree: v => !!v && v.length > 3 || 'Please enter at least 3 characters'
    },
  }),
  methods: {
    selectAddress (address) {
      const { name, locality, postal_code, latitude, longitude, administrative_area_level_1, country, administrative_area_level_2 } = address
      const addressParts = [name, locality, postal_code, administrative_area_level_1, administrative_area_level_2, country]
      const formattedAddress = addressParts.filter(value => !!value).join(', ')
      this.$emit('input', { 
        text: formattedAddress,
        value: [latitude, longitude]
      })
    }
  }
}
</script>